import React from 'react'
import { useKinetiq } from 'kinetiq'
import {
  PageContentWrapper,
  PageContentContainer,
  PageContentBackground,
} from './PageContent.css'

export const PageContent = ({ children }) => {
  const kinetiq = useKinetiq()

  return (
    <div css={PageContentWrapper(kinetiq)}>
      <div css={PageContentBackground(kinetiq)} />
      <div css={PageContentContainer(kinetiq)}>{children}</div>
    </div>
  )
}
