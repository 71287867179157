import React from 'react'
import { useKinetiq } from 'kinetiq'
import { FooterWrapper, FooterContent, FooterBackground } from './Footer.css'

export const Footer = ({ image }) => {
  const kinetiq = useKinetiq()
  return (
    <footer css={FooterWrapper(kinetiq.base)}>
      <div css={FooterBackground(kinetiq)} />
      <div css={FooterContent(kinetiq.base)}>
        <div>{image}</div>
        <span>
          Copyright © {new Date().getFullYear()} Qoor Holdings, Inc. All rights reserved.
        </span>
      </div>
    </footer>
  )
}
