import React from 'react'

import BrandingQoorFooter from '../../../static/branding/qoor-footer.svg'
import BrandingQoorHorizontal from '../../../static/branding/qoor-horizontal.svg'
import BrandingSol from '../../../static/branding/sol.svg'
import BrandingStarship from '../../../static/branding/starship.svg'

import CardIconsSolAdvanced from '../../../static/card-icons/sol/advanced-monitoring.svg'
import CardIconsSolBlazing from '../../../static/card-icons/sol/blazing-hardware.svg'
import CardIconsSolConfigure from '../../../static/card-icons/sol/configure-hardware.svg'
import CardIconsSolRich from '../../../static/card-icons/sol/rich-api.svg'
import CardIconsSolScalable from '../../../static/card-icons/sol/scalable-storage.svg'

import CardIconsStarshipDeep from '../../../static/card-icons/starship/deep-insights.svg'
import CardIconsStarshipDeveloper from '../../../static/card-icons/starship/developer-tools.svg'
import CardIconsStarshipInfrastructure from '../../../static/card-icons/starship/infrastructure-agnostic.svg'
import CardIconsStarshipTeam from '../../../static/card-icons/starship/team-collaboration.svg'
import CardIconsStarshipVersion from '../../../static/card-icons/starship/version-control.svg'

import FrameworksKeras from '../../../static/frameworks/keras.svg'
import FrameworksPycaffe from '../../../static/frameworks/pycaffe.svg'
import FrameworksPytorch from '../../../static/frameworks/pytorch.svg'
import FrameworksSciKitLearn from '../../../static/frameworks/scikit-learn.svg'
import FrameworksTensorflow from '../../../static/frameworks/tensorflow.svg'

import HeroesHomeSol from '../../../static/heroes/home/sol-hero.svg'
import HeroesHomeStarship from '../../../static/heroes/home/starship-hero.svg'
import HeroesHomeNvidia from '../../../static/heroes/home/nvidia-hero.svg'

import HeroesSolHigh from '../../../static/heroes/sol/high-availability.svg'
import HeroesSolPredictable from '../../../static/heroes/sol/predictable-pricing.svg'
import HeroesSolSecurity from '../../../static/heroes/sol/security-compliance.svg'
import HeroesSolWorld from '../../../static/heroes/sol/world-class-support.svg'

import HeroesStarshipBuild from '../../../static/heroes/starship/build.svg'
import HeroesStarshipDeploy from '../../../static/heroes/starship/deploy.svg'
import HeroesStarshipTrain from '../../../static/heroes/starship/train.svg'

import IconsCheck from '../../../static/icons/check-green.svg'
import IconsCheckbox from '../../../static/icons/checkbox-checked.svg'
import IconsClose from '../../../static/icons/close.svg'
import IconsMenu from '../../../static/icons/menu.svg'

import PartnersFireEye from '../../../static/partners/fireeye.svg'
import PartnersGoodwin from '../../../static/partners/goodwin.svg'
import PartnersNvidia from '../../../static/partners/nvidia.svg'

import UseCasesCloud from '../../../static/use-cases/cloud-burst.svg'
import UseCasesData from '../../../static/use-cases/data-science.svg'
import UseCasesEdge from '../../../static/use-cases/edge-compute.svg'
import UseCasesInference from '../../../static/use-cases/inference.svg'
import UseCasesPrivate from '../../../static/use-cases/private-cloud.svg'
import UseCasesTraining from '../../../static/use-cases/training.svg'

import { IconStyle } from './SVGImage.css'

const SVGs = {
  BrandQoorFooter: BrandingQoorFooter,
  BrandQoorHorizontal: BrandingQoorHorizontal,
  BrandSol: BrandingSol,
  BrandStarship: BrandingStarship,
  IconSolAdvanced: CardIconsSolAdvanced,
  IconSolBlazing: CardIconsSolBlazing,
  IconSolConfigure: CardIconsSolConfigure,
  IconSolRich: CardIconsSolRich,
  IconSolScalable: CardIconsSolScalable,
  IconStarshipDeep: CardIconsStarshipDeep,
  IconStarshipDeveloper: CardIconsStarshipDeveloper,
  IconStarshipInfrastructure: CardIconsStarshipInfrastructure,
  IconStarshipTeam: CardIconsStarshipTeam,
  IconStarshipVersion: CardIconsStarshipVersion,
  FrameworkKeras: FrameworksKeras,
  FrameworkPycaffe: FrameworksPycaffe,
  FrameworkPytorch: FrameworksPytorch,
  FrameworkSciKitLearn: FrameworksSciKitLearn,
  FrameworkTensorflow: FrameworksTensorflow,
  HeroHomeSol: HeroesHomeSol,
  HeroHomeStarship: HeroesHomeStarship,
  HeroHomeNvidia: HeroesHomeNvidia,
  HeroSolHigh: HeroesSolHigh,
  HeroSolPredictable: HeroesSolPredictable,
  HeroSolSecurity: HeroesSolSecurity,
  HeroSolWorld: HeroesSolWorld,
  HeroStarshipBuild: HeroesStarshipBuild,
  HeroStarshipDeploy: HeroesStarshipDeploy,
  HeroStarshipTrain: HeroesStarshipTrain,
  IconCheck: IconsCheck,
  IconCheckbox: IconsCheckbox,
  IconClose: IconsClose,
  IconMenu: IconsMenu,
  PartnerFireEye: PartnersFireEye,
  PartnerGoodwin: PartnersGoodwin,
  PartnerNvidia: PartnersNvidia,
  UseCaseCloud: UseCasesCloud,
  UseCaseData: UseCasesData,
  UseCaseEdge: UseCasesEdge,
  UseCaseInference: UseCasesInference,
  UseCasePrivate: UseCasesPrivate,
  UseCaseTraining: UseCasesTraining,
}

export const SVGImage = ({ variant }) => {
  return (
    <svg css={IconStyle}>
      <use xlinkHref={`#${SVGs[variant].id}`} />
    </svg>
  )
}
