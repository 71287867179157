import React from 'react'
import ReactParticles from 'react-particles-js'
// import { navigateCallback, useKinetiq } from 'kinetiq'

import config from './Particles.config'
import { ParticleSystemContainer } from './Particles.css'

export const Particles = () => (
  <div css={ParticleSystemContainer()}>
    {config.map((entry) => (
      <ReactParticles key={entry.slug} params={{ ...entry }} />
    ))}
  </div>
)
