import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const FooterWrapper = () => css`
  position: relative;
  z-index: 1;
`

export const FooterContent = (base) => css`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 51px 0px;

  /* image */
  & > div:nth-of-type(1) {
    width: 198px;
    height: 198px;
  }

  & > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${js2css({
      ...base.typeEditorial.common,
      ...base.typeEditorial.smaller,
      color: base.colorTint._300,
      textAlign: 'center',
    })}
  }

  ${base.MQ.phone} {
    padding: 36px 0px;

    /* image */
    & > div:nth-of-type(1) {
      width: 130px;
      height: 128px;
    }

    & > span {
      width: 263px;
    }
  }
`

export const FooterBackground = (kinetiq) => css`
  position: absolute;
  z-index: 0;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: ${kinetiq.commonTokens.backgroundColor};
`
