import { withPrefix } from 'gatsby'
import _ from 'lodash'

const src = (filename) => withPrefix(`particles/${filename}.png`)

const base = {
  retina_detect: true,
  particles: {
    number: {
      value: 32,
    },
    density: {
      enable: true,
      value_area: 20,
    },
    opacity: {
      value: 1,
    },
    size: {
      value: 80,
      random: false,
    },
    line_linked: {
      enable: false,
      distance: 400,
      width: 1.5,
      color: '#FF0D50',
      opacity: 0.24,
    },
    move: {
      enable: true,
      speed: 4,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: true,
      },
    },
  },
}

const config = ({ count, size, lines = false }) =>
  _.defaultsDeep(
    {},
    {
      slug: `${count}-${size}`,
      particles: {
        number: {
          value: count,
        },
        density: {
          value_area: count * 100,
        },
        shape: {
          type: 'images',
          images: [
            { src: src(`xs/square-filled`), width: size, height: size },
            { src: src(`xs/square-stroked`), width: size, height: size },
            { src: src(`xs/circle-filled`), width: size, height: size },
            { src: src(`xs/circle-stroked`), width: size, height: size },
            { src: src(`xs/triangle-filled`), width: size, height: size },
            { src: src(`xs/triangle-stroked`), width: size, height: size },

            { src: src(`xs/square-filled`), width: size, height: size },
            { src: src(`xs/square-stroked`), width: size, height: size },
            { src: src(`xs/circle-filled`), width: size, height: size },
            { src: src(`xs/circle-stroked`), width: size, height: size },
            { src: src(`xs/triangle-filled`), width: size, height: size },
            { src: src(`xs/triangle-stroked`), width: size, height: size },

            { src: src(`sm/square-filled`), width: size, height: size },
            { src: src(`sm/square-stroked`), width: size, height: size },
            { src: src(`sm/circle-filled`), width: size, height: size },
            { src: src(`sm/circle-stroked`), width: size, height: size },
            { src: src(`sm/triangle-filled`), width: size, height: size },
            { src: src(`sm/triangle-stroked`), width: size, height: size },

            { src: src(`sm/square-filled`), width: size, height: size },
            { src: src(`sm/square-stroked`), width: size, height: size },
            { src: src(`sm/circle-filled`), width: size, height: size },
            { src: src(`sm/circle-stroked`), width: size, height: size },
            { src: src(`sm/triangle-filled`), width: size, height: size },
            { src: src(`sm/triangle-stroked`), width: size, height: size },

            { src: src(`sm/square-filled`), width: size, height: size },
            { src: src(`sm/square-stroked`), width: size, height: size },
            { src: src(`sm/circle-filled`), width: size, height: size },
            { src: src(`sm/circle-stroked`), width: size, height: size },
            { src: src(`sm/triangle-filled`), width: size, height: size },
            { src: src(`sm/triangle-stroked`), width: size, height: size },

            { src: src(`md/square-filled`), width: size, height: size },
            { src: src(`md/square-stroked`), width: size, height: size },
            { src: src(`md/circle-filled`), width: size, height: size },
            { src: src(`md/circle-stroked`), width: size, height: size },
            { src: src(`md/triangle-filled`), width: size, height: size },
            { src: src(`md/triangle-stroked`), width: size, height: size },

            { src: src(`md/square-filled`), width: size, height: size },
            { src: src(`md/square-stroked`), width: size, height: size },
            { src: src(`md/circle-filled`), width: size, height: size },
            { src: src(`md/circle-stroked`), width: size, height: size },
            { src: src(`md/triangle-filled`), width: size, height: size },
            { src: src(`md/triangle-stroked`), width: size, height: size },

            { src: src(`lg/square-filled`), width: size, height: size },
            { src: src(`lg/square-stroked`), width: size, height: size },
            { src: src(`lg/circle-filled`), width: size, height: size },
            { src: src(`lg/circle-stroked`), width: size, height: size },
            { src: src(`lg/triangle-filled`), width: size, height: size },
            { src: src(`lg/triangle-stroked`), width: size, height: size },
          ],
        },
        size: {
          value: size / 2,
        },
        line_linked: {
          enable: lines,
        },
      },
    },
    base,
  )

export default [config({ count: 40, size: 128 })]
