import { css } from '@emotion/core'

// export const NavBarContainer = (isMobile) => css`
//   display: grid;
//   ${isMobile
//     ? 'grid-template-columns: 24px 120px auto 50px 24px;'
//     : 'grid-template-columns: minmax(24px, auto) 120px minmax(auto, 642px) 270px minmax(24px, auto);'}

//   grid-template-areas: '. brand . items .';
//   align-items: center;

//   & > div:nth-of-type(2) {
//     grid-area: brand;
//     justify-self: start;
//     align-self: center;
//     cursor: pointer;

//     /* branding logo */
//     & > div:nth-of-type(1) {
//       width: 122px;
//       height: 40px;
//     }
//   }

//   & > div:nth-of-type(3) {
//     grid-area: items;
//     display: flex;
//     height: 100%;
//     justify-self: end;
//   }
// `

export const MobileNavBarContainer = css``

export const DesktopNavBarContainer = css``

export const BodyContainer = (base) => css`
  margin: 0 auto;
  position: relative;
  z-index: 1;
  max-width: 1032px;
  padding: 0px 24px;
`

export const BodyWrapper = (kinetiq) => css`
  background: ${kinetiq.commonTokens.backgroundColor};
`
