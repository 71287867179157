import { css } from '@emotion/core'

export const ParticleSystemContainer = () => css`
  &,
  & > div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1280px;

    @media (min-width: 900px) {
      position: fixed;
      z-index: 0;
      height: 100vh;
    }
  }
`
