import { css } from '@emotion/core'

export const PageContentWrapper = (kinetiq) => css`
  position: relative;
  z-index: 1;
`

export const PageContentContainer = (kinetiq) => css`
  position: relative;
  z-index: 1;
  padding-top: 1px;
`

export const PageContentBackground = (kinetiq) => css`
  position: absolute;
  z-index: 0;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: ${kinetiq.commonTokens.backgroundColor};
`
