/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react'
import {
  NavBar,
  NavBarItem,
  NavBarDropDown,
  NavBarMobile,
  NavBarMobileDropDownItemList,
  NavBarMobileSection,
  navigateCallback,
  useKinetiq,
} from 'kinetiq'
import PropTypes from 'prop-types'
import { SVGImage } from '../SVGImage'
import {
  MobileNavBarContainer,
  DesktopNavBarContainer,
  BodyContainer,
  BodyWrapper,
} from './Layout.css'
import { Footer } from '../Footer'
import { Particles } from '../Particles'

export const Layout = ({ children }) => {
  const kinetiq = useKinetiq()
  const [dropdownIsToggled, setDropdownIsToggled] = useState(false)

  const mobileNavBar = (
    <NavBarMobile
      extraStyles={MobileNavBarContainer}
      brand={{
        image: <SVGImage variant='BrandQoorHorizontal' />,
        onClickHandler: navigateCallback('/'),
      }}
      hamburger={<SVGImage variant='IconMenu' />}
      close={<SVGImage variant='IconClose' />}
      items={[
        <NavBarMobileSection
          title='Solutions'
          items={[
            {
              icon: <SVGImage variant='BrandSol' />,
              text: 'Sol',
              onClickHandler: navigateCallback('/sol'),
            },
            {
              icon: <SVGImage variant='BrandStarship' />,
              text: 'Starship',
              onClickHandler: navigateCallback('/starship'),
            },
          ]}
        />,
        <NavBarMobileDropDownItemList
          items={[
            { text: 'About', onClickHandler: navigateCallback('/about') },
            { text: 'Contact', onClickHandler: navigateCallback('/contact') },
          ]}
        />,
      ]}
    />
  )

  const desktopNavBar = (
    <NavBar
      extraStyles={DesktopNavBarContainer}
      dropdownIsToggled={dropdownIsToggled}
      brand={{
        image: <SVGImage variant='BrandQoorHorizontal' />,
        onClickHandler: navigateCallback('/'),
      }}
      items={[
        <NavBarDropDown
          title='Solutions'
          dropdownToggled={(t) => setDropdownIsToggled(t)}
          items={[
            {
              icon: <SVGImage variant='BrandSol' />,
              text: 'Sol',
              onClickHandler: navigateCallback('/sol'),
            },
            {
              icon: <SVGImage variant='BrandStarship' />,
              text: 'Starship',
              onClickHandler: navigateCallback('/starship'),
            },
          ]}
        />,
        <NavBarItem item={{ text: 'About', onClickHandler: navigateCallback('/about') }} />,
        <NavBarItem item={{ text: 'Contact', onClickHandler: navigateCallback('/contact') }} />,
      ]}
    />
  )

  return (
    <>
      {/* {isMobile ? mobileNavBar : desktopNavBar} */}
      {mobileNavBar}
      {desktopNavBar}
      <div css={BodyWrapper(kinetiq)}>
        <Particles />
        <div css={BodyContainer(kinetiq.base)}>
          <main>{children}</main>
          <Footer image={<SVGImage variant='BrandQoorFooter' />} />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
